import React from "react";
import { useTranslation } from "react-i18next";
import Statistics from "../../../images/Group 15.svg";
import Video from "../../../images/Rectangle 3.svg";
import Smoke from "../../../images/klipartz 1.svg";
import Play from "../../../images/Frame 6.svg";
import VideoReal from "../../../images/3406240848280246524.mp4";

const AirBusines = () => {
  const { t } = useTranslation();

  return (
    <div className="airBusines">
      <div className="container">
        <div className="firstBusines">
          <div className="forWord">
            <h1>
              {t("properly_applied")} <span>{t("aroma")}</span>
              {t("marketing_stimulates_business_growth")}
            </h1>
            <p>{t("successful_tasks")}</p>
          </div>
          <div className="forImage">
            <img src={Statistics} alt="" />
          </div>
        </div>
        <div className="secondBusines">
          <div className="above">
            <div className="inner">
              <h1>
                <span>{t("airlab")}</span>
                {t("team_of_professionals")}
              </h1>
              <p>{t("main_goal")}</p>
            </div>
          </div>
          <div className="below">
            <img className="videoImg" src={Video} alt="" />
            <img className="smoke" src={Smoke} alt="" />
            <div className="play">
              <img src={Play} alt="" />
              <p>{t("learn_more_about_us")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirBusines;
