import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import Axios from "../../../helper/httpClient";
import Play from "../../../images/Frame 6.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Keyboard } from "swiper/modules";

const VideoKeys = () => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const videoRefs = useRef([]);
  const [showPlayIcons, setShowPlayIcons] = useState([]);

  useEffect(() => {
    getVideo();
  }, []);

  const getVideo = () => {
    Axios()
      .get(`/api/v1/base/video-cases`)
      .then((res) => {
        setVideos(res.data);
        setShowPlayIcons(Array(res.data.length).fill(true));
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handlePlayClick = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
      const newShowPlayIcons = [...showPlayIcons];
      newShowPlayIcons[index] = false;
      setShowPlayIcons(newShowPlayIcons);
    }
  };

  const handleVideoClick = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
      const newShowPlayIcons = [...showPlayIcons];
      newShowPlayIcons[index] = true;
      setShowPlayIcons(newShowPlayIcons);
    }
  };

  const handleVideoPause = (index) => {
    const newShowPlayIcons = [...showPlayIcons];
    newShowPlayIcons[index] = true;
    setShowPlayIcons(newShowPlayIcons);
  };

  const handleSwiperSlideChange = () => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.pause();
        const newShowPlayIcons = [...showPlayIcons];
        newShowPlayIcons[index] = true;
        setShowPlayIcons(newShowPlayIcons);
      }
    });
  };

  return (
    <div className="videoKeys">
      <div className="container">
        <div className="videoAll">
          <div className="info">
            <h1>
              {t("introducing_you")} <span>{t("video_cases")}</span>
              {t("demonstrating_real_processes")}
            </h1>
          </div>
          <div className="courses">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                675: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                793: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
              }}
              navigation={true}
              pagination={true}
              keyboard={true}
              modules={[Navigation, Pagination, Keyboard]}
              className="mySwiper"
              onSlideChange={handleSwiperSlideChange}
            >
              {videos.map((video, index) => (
                <SwiperSlide key={video.id}>
                  <div className="video-wrapper">
                    <video
                      width="100%"
                      height="auto"
                      controls={false}
                      ref={(el) => (videoRefs.current[index] = el)}
                      onClick={() => handleVideoClick(index)}
                      onPause={() => handleVideoPause(index)}
                      onEnded={() => handleVideoPause(index)}
                    >
                      <source src={video.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {showPlayIcons[index] && (
                      <img
                        src={Play}
                        alt="Play"
                        className="play"
                        onClick={() => handlePlayClick(index)}
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoKeys;
