import React, { useState, useEffect } from "react";
import ArrowAbove from "../../../images/Group 50 copy 3.svg";
import Axios from "../../../helper/httpClient";
import FullArrow from "../../../images/Frame 39.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Catalog = () => {
  const { t } = useTranslation();
  const [visibleProducts, setVisibleProducts] = useState(5);
  const [loading, setLoading] = useState(false);
  const [catalog, setCatalog] = useState([]);
  const [hasMoreProducts, setHasMoreProducts] = useState(true); // Track if more products are available

  useEffect(() => {
    getCatalog();
  }, [visibleProducts]);

  const getCatalog = () => {
    setLoading(true);
    Axios()
      .get(`/api/v1/product/sprayers/`)
      .then((res) => {
        const allProducts = res.data.results;
        setCatalog(allProducts.slice(0, visibleProducts)); // Show only the number of visibleProducts
        if (visibleProducts >= allProducts.length) {
          setHasMoreProducts(false); // No more products to load
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const loadMoreProducts = () => {
    setVisibleProducts((prev) => prev + 5); // Load 5 more products
  };

  return (
    <div className="portLocal">
      <div className="catalog">
        <div className="container">
          <div className="navigated">
            <NavLink to="/">
              <p>{t("home")} |</p>
            </NavLink>
            <h5> {t("portfolio")}</h5>
          </div>
          <div className="title">
            <h1>
              {t("familiarize_with_our")} <span>{t("diffusers")}</span>
            </h1>
            <p>{t("diffuser_description")}</p>
          </div>
          <div className="catalogAll">
            {catalog.map((item, index) => (
              <div key={index} className="catalogCard">
                <div className="card">
                  <div className="number">
                    <img
                      src={`../../../images/Frame 12 copy${index % 3}.svg`}
                      alt=""
                    />
                    <span className="mainTartib">
                      <p className="tartib">{index + 1}</p>
                      {item.model.name}
                    </span>
                  </div>
                  <div className="parametr">
                    <h1>{t("parameters")}</h1>
                    <div className="innerParametr">
                      <p>{t("material")}</p>
                      <p>{item.material.name}</p>
                    </div>
                    <div className="innerParametr">
                      <p>{t("power")}</p>
                      <p>{item.power}</p>
                    </div>
                    <div className="innerParametr">
                      <p>{t("size")}</p>
                      <p>{item.volume}</p>
                    </div>
                    <div className="innerParametr">
                      <p>{t("capacity")}</p>
                      <p>{item.capacity} мл</p>
                    </div>
                    <div className="innerParametr">
                      <p>{t("coverage")}</p>
                      <p>{item.area.metr_kv} куб/м</p>
                    </div>
                    <div className="innerParametr">
                      <p>{t("weight")}</p>
                      <p>{item.weight} кг</p>
                    </div>
                  </div>
                </div>
                <div className="card2">
                  <img src={item.image} alt="" />
                </div>
                <div className="card">
                  <div className="parametr">
                    <h1>{t("features")}</h1>
                    {item.properties.length > 0 ? (
                      item.properties.map((property, i) => (
                        <div key={i} className="innerParametr">
                          <p>{property}</p>
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="innerParametr">
                          <p>{t("silent_spray")}</p>
                        </div>
                        <div className="innerParametr">
                          <p>{t("turbo_fan")}</p>
                        </div>
                        <div className="innerParametr">
                          <p>{t("magnetic_lock")}</p>
                        </div>
                        <div className="innerParametr">
                          <p>{t("mobile_app_control")}</p>
                        </div>
                        <div className="innerParametr">
                          <p>{t("five_time_phases")}</p>
                        </div>
                        <div className="innerParametr">
                          <p>{t("hvac_connection")}</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {hasMoreProducts && (
            <div className="portButton">
              <button onClick={loadMoreProducts} disabled={loading}>
                {loading ? "Загрузка..." : ""}
                {!loading && <img src={FullArrow} alt="" />}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
