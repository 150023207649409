import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../../helper/httpClient";

const LogoSlider = () => {
  const { t } = useTranslation();
  const [slideCount, setSlideCount] = useState(1);
  const [hamkor, setHamkor] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideCount((prevCount) => prevCount + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getHamkor();
  }, []);

  const getHamkor = () => {
    Axios()
      .get(`/api/v1/base/partners`)
      .then((res) => {
        setHamkor(res.data);
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <div className="hamkor">
      <div className="container">
        <div className="title">
          <h1>
            <span>Airlab</span>
            {t("cooperates_with_many_companies_across_Uzbekistan")}
          </h1>
        </div>
      </div>
      <div className="logo-slider">
        {[...Array(slideCount)].map((_, index) => (
          <div className="logos-slide" key={index}>
            {hamkor.map((item) => (
              <a
                key={item.id}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={item.image} alt={`Brand ${item.id}`} />
              </a>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoSlider;
