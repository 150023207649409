import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../images/Group 3.svg";
import ArrowBelow from "../../images/Group 50.svg";
import Hamburger from "../../images/Group 30.svg";
import Close from "../../images/Group 30 copy.svg";
import Map from "../../images/map-pin.svg";
import Call from "../../images/Vector.svg";
import { NavLink } from "react-router-dom";

const Header = ({
  scrollToAboutUs,
  scrollToVideoblog,
  scrollToCatalog,
  scrollToContacts,
  scrollToSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [hamburgerOpen, setHamburgerOpen] = useState(true);

  const handleHamburgerClick = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
    window.localStorage.setItem("i18nextLng", lng);
  };

  return (
    <div className="header">
      <div className="container">
        <div className="navbar">
          <div onClick={scrollToAboutUs} className="logo">
            <NavLink to="/">
              <img src={Logo} alt="" />
            </NavLink>
          </div>
          <div className="navlink">
            <ul className={hamburgerOpen ? "hide" : ""}>
              <li>
                <a onClick={scrollToAboutUs}>{t("about_us")}</a>
              </li>
              <li>
                <a onClick={scrollToVideoblog}>{t("videoblog")}</a>
              </li>
              <li>
                <a onClick={scrollToCatalog}>{t("catalog")}</a>
              </li>
              <li>
                <a onClick={scrollToContacts}>{t("contacts")}</a>
              </li>
              <li>
                <a
                  onClick={() => changeLanguage("ru")}
                  className={activeLanguage === "ru" ? "active" : ""}
                >
                  Рус
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => changeLanguage("uz")}
                  className={activeLanguage === "uz" ? "active" : ""}
                >
                  O’zb
                </a>
              </li>
              <button onClick={scrollToSubmit}>
                {t("apply_to_business")} <img src={ArrowBelow} alt="" />
              </button>
            </ul>
            <div
              className={`hamburger ${hamburgerOpen ? "active" : ""}`}
              onClick={handleHamburgerClick}
            >
              <img src={Hamburger} alt="" />
            </div>
          </div>
          <div className={hamburgerOpen ? "hide" : "opened"}>
            <img className="logoHamburger" src={Logo} alt="" />
            <ul>
              <li>
                <a onClick={scrollToAboutUs}>{t("about_us")}</a>
              </li>
              <li>
                <a onClick={scrollToVideoblog}>{t("videoblog")}</a>
              </li>
              <li>
                <a onClick={scrollToCatalog}>{t("catalog")}</a>
              </li>
              <li>
                <a onClick={scrollToContacts}>{t("contacts")}</a>
              </li>
              <div className="language">
                <li>
                  <a
                    onClick={() => changeLanguage("ru")}
                    className={activeLanguage === "ru" ? "active" : ""}
                  >
                    Рус
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => changeLanguage("uz")}
                    className={activeLanguage === "uz" ? "active" : ""}
                  >
                    O’zb
                  </a>
                </li>
              </div>
              <button onClick={scrollToSubmit}>
                {t("apply_to_business")} <img src={ArrowBelow} alt="" />
              </button>
            </ul>
            <div className="contactAll">
              <div className="contactCard">
                <h1>{t("address")}</h1>
                <div className="double">
                  <a
                    target="blank"
                    href="https://maps.app.goo.gl/9pMeNUJ2YDUVcGcR8"
                  >
                    {t("address_detail")}
                  </a>
                  <img src={Map} alt="" />
                </div>
              </div>
              <div className="contactCard">
                <h1>{t("phone_number")}</h1>
                <div className="double">
                  <a target="blank" href="tel:+998950441111">
                    +998950441111 +998990661111
                  </a>
                  <img src={Call} alt="" />
                </div>
              </div>
            </div>
            <img
              className="close"
              onClick={handleHamburgerClick}
              src={Close}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
