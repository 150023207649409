import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LeftToRight from "./LeftToRight";
import RightToLeft from "./RightToLeft";
import RightToLeft2 from "./RightToLeft2";

const Coworkers = () => {
  const { t } = useTranslation();
  const [slideCount, setSlideCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideCount((prevCount) => prevCount + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="coworkers">
      <div className="container">
        <div style={{ marginBottom: "25px" }} className="title">
          <h1>
            {t("aroma_marketing_useful_for")} <span>{t("business_types")}</span>
          </h1>
        </div>
      </div>
      <div className="logo-slider">
        <div className="logos-track">
          <RightToLeft />
        </div>
        <div className="logos-track">
          <LeftToRight />
        </div>
        <div className="logos-track">
          <RightToLeft2 />
        </div>
      </div>
    </div>
  );
};

export default Coworkers;
