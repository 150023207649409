import React from "react";
import { useTranslation } from "react-i18next";
import Map from "../../../images/map-pin.svg";
import Call from "../../../images/Vector.svg";
import Instagram from "../../../images/instagram.svg";
import Telegram from "../../../images/iconoir_telegram.svg";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact">
      <div className="container">
        <div className="title">
          <h1>
            <span>{t("subscribe_to_us")}</span>
            {t("social_media_or_message_us")}
          </h1>
        </div>
        <div className="contactAll">
          <div className="contactCard">
            <h1>{t("address2")}</h1>
            <div className="double">
              <a
                target="blank"
                href="https://maps.app.goo.gl/9pMeNUJ2YDUVcGcR8"
              >
                {t("address_detail")}
              </a>
              <img src={Map} alt="Map icon" />
            </div>
          </div>
          <div className="contactCard">
            <h1>{t("phone_number2")}</h1>
            <div className="double">
              <a target="blank" href="tel:+998950441111">
                +998950441111 <br /> +998990661111
              </a>
              <img src={Call} alt="Call icon" />
            </div>
          </div>
          <div className="contactCard">
            <h1>Instagram</h1>
            <div style={{ marginTop: "150px" }} className="double">
              <a target="blank" href="https://www.instagram.com/">
                @airlab.uz
              </a>
              <img src={Instagram} alt="Instagram icon" />
            </div>
          </div>
          <div className="contactCard">
            <h1>Telegram</h1>
            <div style={{ marginTop: "150px" }} className="double">
              <a target="blank" href="https://web.telegram.org/a/">
                @Airlab1
              </a>
              <img src={Telegram} alt="Telegram icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;