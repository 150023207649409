import React, { useState, useEffect, useRef } from "react";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import HeaderBelow from "../components/local/home/HeaderBelow";
import AirBusines from "../components/local/home/AirBusines";
import VideoKeys from "../components/local/home/VideoKeys";
import Catalog from "../components/local/home/Catalog";
import Coworkers from "../components/local/home/Coworkers";
import Hamkor from "../components/local/home/Hamkor";
import Contact from "../components/local/home/Contact";
import Submit from "../components/local/home/Submit";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  const aboutUsRef = useRef(null);
  const videoblogRef = useRef(null);
  const catalogRef = useRef(null);
  const contactsRef = useRef(null);
  const submitRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Header
            scrollToAboutUs={() => scrollToSection(aboutUsRef)}
            scrollToVideoblog={() => scrollToSection(videoblogRef)}
            scrollToCatalog={() => scrollToSection(catalogRef)}
            scrollToContacts={() => scrollToSection(contactsRef)}
            scrollToSubmit={() => scrollToSection(submitRef)}
          />
          <div ref={aboutUsRef}>
            <HeaderBelow />
          </div>
          <div>
            <AirBusines />
          </div>
          <div ref={videoblogRef}>
            <VideoKeys />
          </div>
          <div ref={catalogRef}>
            <Catalog />
          </div>
          <Coworkers />
          <Hamkor />
          <div ref={contactsRef}>
            <Contact />
          </div>
          <div ref={submitRef}>
            <Submit />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

const Loader = () => {
  return (
    <div className="mainLoader">
      <div className="loader"></div>
    </div>
  );
};

export default Home;
