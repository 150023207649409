import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const InfiniteScrollLeftToRight = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  const { t } = useTranslation();

  return (
    <div className="reversed">
      <div className="scroller" data-direction="left" data-speed="slow">
        <div className="scroller__inner">
          <p> {t("restaurant")}</p>
          <p> {t("autosalon")}</p>
          <p> {t("kafe")}</p>
          <p> {t("ofis")}</p>
          <p> {t("klinika")}</p>
          <p> {t("kayp")}</p>
          <p> {t("zal")}</p>
          <p> {t("kvartira")}</p>
          <p> {t("sfer")}</p>
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollLeftToRight;
