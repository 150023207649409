import React, { useState, useEffect } from "react";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import PortLocal from "../components/local/portfolio/PortLocal";
import Contact from "../components/local/home/Contact";
import Submit from "../components/local/home/Submit";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Header />
          <PortLocal />
          <Contact />
          <Submit />
          <Footer />
        </>
      )}
    </div>
  );
};

const Loader = () => {
  return (
    <div className="mainLoader">
      <div class="loader"></div>
    </div>
  );
};

export default Home;
