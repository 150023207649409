import axios from "axios";
import { API_URL } from "./api";

const Axios = (access_token = "") => {
  const token = access_token || localStorage.getItem("token");
  const defaultOptions = {
    baseURL: API_URL,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
          lang: localStorage.getItem("i18nextLng") || "uz",
        }
      : { lang: localStorage.getItem("i18nextLng") || "uz" },
  };
  return {
    get: (url, options = {}) =>
      axios.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    patch: (url, data, options = {}) =>
      axios.patch(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) =>
      axios.delete(url, { ...defaultOptions, ...options }),
  };
};

export default Axios;
