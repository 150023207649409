import React from "react";
import { useTranslation } from "react-i18next";
import Animation from "../../../images/Animation.svg";
import ArrowBelow from "../../../images/Group 50 copy.svg";

const HeaderBelow = () => {
  const { t } = useTranslation();

  return (
    <div className="below">
      <div className="container">
        <div className="belowWrapper">
          <div className="writeSection">
            <p>{t("add_magic")}</p>
            <h1>
              <span>{t("aroma")}</span>
              {t("magic_in_tashkent")}
            </h1>
            <button>
              {t("use_magic")} <img src={ArrowBelow} alt="" />
            </button>
          </div>
          <div className="animation">
            <img src={Animation} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBelow;
