import React from "react";
import Logo from "../../images/Group 3.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footerAll">
          <div className="left">
            <img src={Logo} alt="" />
          </div>
          <div className="right">
            <p>© 2024 Airlab LLS. All Rights Reserved</p>
            <p>Privacy and Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
