import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../../helper/httpClient";
import ArrowAbove from "../../../images/Group 50 copy 2.svg";
import { NavLink } from "react-router-dom";

const Catalog = () => {
  const { t } = useTranslation();
  const [catalog, setCatalog] = useState([]);

  useEffect(() => {
    getCatalog();
  }, []);

  const getCatalog = () => {
    Axios()
      .get(`/api/v1/product/sprayers/`)
      .then((res) => {
        setCatalog(res.data.results.slice(0, 4));
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <div className="catalog">
      <div className="container">
        <div className="title">
          <h1>
            {t("explore_our")} <span>{t("diffusers")}</span>
          </h1>
          <div className="double">
            <p>{t("diffuser_description")}</p>
            <NavLink to="/portfolio">
              <button>
                {t("use_magic")} <img src={ArrowAbove} alt="" />
              </button>
            </NavLink>
          </div>
        </div>
        <div className="catalogAll">
          {catalog.map((item, index) => (
            <div key={index} className="catalogCard">
              <div className="card">
                <div className="number">
                  <img
                    src={`../../../images/Frame 12 copy${index % 3}.svg`}
                    alt=""
                  />
                  <span className="mainTartib">
                    <p className="tartib">{index + 1}</p>
                    {item.model.name}
                  </span>
                </div>
                <div className="parametr">
                  <h1>{t("parameters")}</h1>
                  <div className="innerParametr">
                    <p>{t("material")}</p>
                    <p>{item.material.name}</p>
                  </div>
                  <div className="innerParametr">
                    <p>{t("power")}</p>
                    <p>{item.power}</p>
                  </div>
                  <div className="innerParametr">
                    <p>{t("size")}</p>
                    <p>{item.volume}</p>
                  </div>
                  <div className="innerParametr">
                    <p>{t("capacity")}</p>
                    <p>{item.capacity} мл</p>
                  </div>
                  <div className="innerParametr">
                    <p>{t("coverage")}</p>
                    <p>{item.area.metr_kv} куб/м</p>
                  </div>
                  <div className="innerParametr">
                    <p>{t("weight")}</p>
                    <p>{item.weight} кг</p>
                  </div>
                </div>
              </div>
              <div className="card2">
                <img src={item.image} alt="" />
              </div>
              <div className="card">
                <div className="parametr">
                  <h1>{t("features")}</h1>
                  {item.properties.length > 0 ? (
                    item.properties.map((property, i) => (
                      <div key={i} className="innerParametr">
                        <p>{property}</p>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="innerParametr">
                        <p>{t("silent_spray")}</p>
                      </div>
                      <div className="innerParametr">
                        <p>{t("turbo_fan")}</p>
                      </div>
                      <div className="innerParametr">
                        <p>{t("magnetic_lock")}</p>
                      </div>
                      <div className="innerParametr">
                        <p>{t("mobile_app_control")}</p>
                      </div>
                      <div className="innerParametr">
                        <p>{t("five_time_phases")}</p>
                      </div>
                      <div className="innerParametr">
                        <p>{t("hvac_connection")}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="showButton">
          <NavLink to="/portfolio">
            <button>
              {t("use_magic")} <img src={ArrowAbove} alt="" />
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
