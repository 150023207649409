import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../../images/Group 50 copy 3.svg";
import Animation from "../../../images/Animation.svg";

const Submit = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("+998-");
  const [name, setName] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    const formattedInput = formatPhoneNumber(input);
    setPhoneNumber(formattedInput);
  };

  const formatPhoneNumber = (input) => {
    let formattedInput = input.startsWith("998") ? input : "998" + input;
    formattedInput = formattedInput.substring(0, 12);

    const parts = [
      formattedInput.substring(0, 3),
      formattedInput.substring(3, 5),
      formattedInput.substring(5, 8),
      formattedInput.substring(8, 10),
      formattedInput.substring(10, 12),
    ];

    return `+${parts.filter((part) => part).join("-")}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const botToken = "7033127090:AAH-kGnUAXm3CnI3pTnxJifDGsOCo8bQdDI";
    const chatId = "-1002011287292";
    const message = `New form submission:\nName: ${name}\nPhone Number: ${phoneNumber}`;

    try {
      const response = await fetch(
        `https://api.telegram.org/bot${botToken}/sendMessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        }
      );

      if (response.ok) {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000);
        setName("");
        setPhoneNumber("+998-");
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="submit">
      <div className="container">
        <div className="title">
          <h1>
            <span>{t("want_to_apply")}</span>{" "}
            {t("in_your_business_or_have_questions")}
          </h1>
        </div>
        <div className="inputCard">
          <div className="input">
            <form onSubmit={handleSubmit}>
              <div>
                <div style={{ marginBottom: "50px" }}>
                  <input
                    type="text"
                    value={name}
                    placeholder={t("name_placeholder")}
                    onChange={handleNameChange}
                    required
                  />
                  <p className="firstP">{t("what_is_your_name")}</p>
                </div>
                <div>
                  <input
                    placeholder={t("phone_number")}
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                  />
                  <p className="secondP">{t("phone_number")}</p>
                </div>
              </div>
              <div className="buttons">
                <button type="submit">
                  {t("submit")} <img src={Arrow} alt="arrow" />
                </button>
                <h5>
                  {t("by_clicking_submit")} <span>{t("privacy_policy")}</span>
                </h5>
              </div>
            </form>
          </div>
        </div>
      </div>
      <img className="animation" src={Animation} alt="animation" />
      {showToast && <div className="custom-toast">{t("success_message")}</div>}
    </div>
  );
};

export default Submit;
